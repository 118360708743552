// import blueGrey from "@material-ui/core/esm/colors/blueGrey";
// import red from "@material-ui/core/esm/colors/red";
// import pink from "@material-ui/core/esm/colors/pink";
// import purple from "@material-ui/core/esm/colors/purple";
// import deepPurple from "@material-ui/core/esm/colors/deepPurple";
// import indigo from "@material-ui/core/esm/colors/indigo";
// import blue from "@material-ui/core/esm/colors/blue";
// import lightBlue from "@material-ui/core/esm/colors/lightBlue";
// import cyan from "@material-ui/core/esm/colors/cyan";
// import teal from "@material-ui/core/esm/colors/teal";
// import green from "@material-ui/core/esm/colors/green";
// import lightGreen from "@material-ui/core/esm/colors/lightGreen";
// import lime from "@material-ui/core/esm/colors/lime";
// import yellow from "@material-ui/core/esm/colors/yellow";
// import amber from "@material-ui/core/esm/colors/amber";
// import orange from "@material-ui/core/esm/colors/orange";
import deepOrange from "@material-ui/core/esm/colors/deepOrange";
import grey from "@material-ui/core/esm/colors/grey";
import { alpha } from "@material-ui/core/esm/styles/colorManipulator";
import createTheme from "@material-ui/core/esm/styles/createTheme";


export default createTheme({
	
	palette: {
		primary: deepOrange,
		secondary: grey,
		type: "light"
	},
	
	typography: {
		
		h1: {
			fontSize: "3.5rem"
		},
		
		h2: {
			fontSize: "2.125rem"
		},
		
		h3: {
			fontSize: "1.85rem"
		},
		
		h4: {
			fontSize: "1.3rem"
		},
		
		h5: {
			fontSize: "1rem"
		},
		
		h6: {
			fontSize: ".9rem"
		}
		
	},
	
	props: {
		
		MuiDialogTitle: {
			disableTypography: true
		}
		
	},
	
	overrides: {
		
		MuiFormHelperText: {
			
			root: {
				display: "flex",
				flexFlow: "row wrap"
			}
		},
		
		MuiSwitch: {
			
			root: {
				verticalAlign: "bottom"
			}
		},
		
		PrivateNotchedOutline: {
			
			legendLabelled: {
				whiteSpace: "nowrap",
				fontSize: ".75rem"
			}
		}
		
	},
	
	alpha
	
});
