import clsx from "clsx";
import Button from "@material-ui/core/esm/Button";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		minWidth: "unset",
		textTransform: "initial",
		padding: [ 0, spacing(.5) ],
		fontSize: "inherit",
		lineHeight: "inherit",
		letterSpacing: "inherit",
		
		"&:first-child": {
			marginLeft: -spacing(.5)
		}
	}
	
}), "HelperTextButton");


export function HelperTextButton({ className, ...rest }) {
	return (
		<Button
			className={clsx(classes.root, className)}
			component="span"
			size="small"
			{...rest}
		/>
	);
}
