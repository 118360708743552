import { Component } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { inSite } from "$app";
import { createStyles } from "$styles";


const classes = createStyles({
	
	root: {
		position: "relative"
	}
	
}, "EmployeesInput");


export class EmployeesInput extends Component {
	
	#handleRenderOption = user => (
		<span>
			{user.displayLabel}
		</span>
	);
	
	#handleRenderInput = params => {
		
		const { length } = this.props.org.users.sorted;
		
		return (
			<TextField {...params} label={`Сотрудники${length ? ` (${length})` : ""}`} variant="outlined" />
		);
	};
	
	#ChipProps = { size: "small" };
	
	#handleChange = (_, __, action, { option: user }) =>
		inSite.ws.sendRequest("users.people.update", user._id, { org: action === "select-option" ? this.props.org._id : null });
	
	#handleGetOptionLabel = user => user.displayLabel;
	
	
	render() {
		
		const { className, org, ...restProps } = this.props;
		
		const options = [ ...org.users, ...inSite.orgs.null.users ].sort(inSite.users.sortCompareFunction);
		
		return (
			<div className={clsx(classes.root, className)}>
				<Autocomplete
					value={org.users.sorted}
					ChipProps={this.#ChipProps}
					disableClearable
					disableCloseOnSelect
					filterSelectedOptions
					getOptionLabel={this.#handleGetOptionLabel}
					multiple
					noOptionsText="Пусто"
					options={options}
					renderInput={this.#handleRenderInput}
					renderOption={this.#handleRenderOption}
					onChange={this.#handleChange}
					{...restProps}
				/>
			</div>
		);
	}
	
}
