import { Component } from "react";
import clsx from "clsx";
import { ContextMenuItem } from "insite-client-react";
import Typography from "@material-ui/core/esm/Typography";
import { inSite } from "$app";
import { PermissionsInput } from "$components/PermissionsInput";
import { SaveableTextField } from "$components/SaveableTextField";
import { SelectablePaper } from "$components/SelectablePaper";
import { createStyles } from "$styles";
import { EmployeesInput } from "./EmployeesInput";
import { SlaveOrgsInput } from "./SlaveOrgsInput";


const classes = createStyles(({ spacing, palette, typography }) => ({
	
	root: {
		
		"& .MuiInputBase-root": {
			fontSize: "inherit",
			color: "inherit"
		}
	},
	
	slave: {
		marginLeft: `calc(-${spacing(2.5)}px + ${spacing(1.75)}px * var(--level))`
	},
	
	header: {
		display: "flex",
		flexFlow: "row nowrap",
		
		"$slave &:before": {
			content: "\"└\"",
			width: spacing(2.5),
			opacity: .12
		}
	},
	
	title: {
		alignSelf: "center",
		marginRight: spacing(1.5),
		fontSize: typography.h5.fontSize
	},
	
	titleTextField: {
		flex: "1 1 auto",
		marginRight: spacing(1.5),
		fontSize: typography.h5.fontSize
	},
	
	details: {
		// paddingLeft: spacing(2),
		display: "flex",
		flexFlow: "column nowrap"
	},
	
	note: {
		fontSize: typography.body2.fontSize,
		color: palette.text.secondary,
		whiteSpace: "pre-wrap",
		marginTop: spacing(1)
	},
	
	noteTextField: {
		fontSize: typography.body2.fontSize,
		color: palette.text.secondary,
		margin: [ spacing(.5), 0, spacing(2) ]
	},
	
	owners: {
		marginBottom: spacing(3)
	},
	
	slaveOrgs: {
		marginBottom: spacing(3)
	},
	
	employees: {
		marginBottom: spacing(1)
	}
	
}), "Org");


export class Org extends Component {
	
	#handleTitleSave = title => inSite.ws.sendRequest("users.orgs.update", this.props.org._id, { title });
	
	#handleNoteSave = note => inSite.ws.sendRequest("users.orgs.update", this.props.org._id, { note });
	
	#handleDeleteClick = () =>
		confirm(`Удалить организацию ${this.props.org.title}?`) &&
		inSite.ws.sendRequest("users.orgs.delete", this.props.org._id);
	
	#handleOwnersChange = (_, owners) => inSite.ws.sendRequest("users.orgs.update", this.props.org._id, { owners: owners.ids() });
	
	
	render() {
		
		const { org } = this.props;
		
		const isSelected = this.context === org;
		
		return (
			<div
				className={clsx(
					classes.root,
					org._l && classes.slave
				)}
				style={{ "--level": org._l }}
			>
				<SelectablePaper
					datum={org}
					menu={(
						<ContextMenuItem onClick={this.#handleDeleteClick}>
							Удалить…
						</ContextMenuItem>
					)}
				>
					<div className={classes.header}>
						{isSelected ? (
							<SaveableTextField
								className={classes.titleTextField}
								label="Название"
								value={org.title}
								autoComplete="off"
								onSave={this.#handleTitleSave}
							/>
						) : org.title && (
							<Typography className={classes.title}>
								{org.title}
							</Typography>
						)}
					</div>
					
					<div className={classes.details}>
						{isSelected ? (
							<>
								
								<SaveableTextField
									className={classes.noteTextField}
									label="Описание"
									value={org.note}
									fullWidth
									multiline
									onSave={this.#handleNoteSave}
								/>
								
								<PermissionsInput
									className={classes.owners}
									label="Владельцы"
									value={org.owners}
									disablePortal
									options={inSite.user.slaves}
									without={[ org, ...org.slaveOrgs ]}
									onChange={this.#handleOwnersChange}
								/>
								
								<SlaveOrgsInput className={classes.slaveOrgs} disablePortal org={org} />
								
								<EmployeesInput className={classes.employees} disablePortal org={org} />
							
							</>
						) : org.note && (
							<Typography className={classes.note}>
								{org.note}
							</Typography>
						)}
					</div>
				</SelectablePaper>
			</div>
		);
	}
	
	
	static contextType = SelectablePaper.contextType;
	
}
