import { Component } from "react";
import { howmuch } from "@nesvet/n";
import { Subscription } from "insite-subscriptions-react";
import Checkbox from "@material-ui/core/esm/Checkbox";
import FormControlLabel from "@material-ui/core/esm/FormControlLabel";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { inSite } from "$app";
import { HelperTextButton } from "$components/HelperTextButton";
import { Loading } from "$components/Loading";
import { SaveableTextField } from "$components/SaveableTextField";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative",
		flex: "1 0 auto",
		display: "flex",
		flexFlow: "column nowrap",
		gap: spacing(2)
	},
	
	row: {
		flex: "0 0 auto",
		display: "flex",
		flexFlow: "column wrap"
	},
	
	indent: {
		flex: "1 0 auto",
		display: "flex",
		flexFlow: "column nowrap",
		paddingLeft: spacing(4)
	},
	
	campTokenInput: {
		
		"& .MuiInput-root": {
			fontFamily: "monospace"
		}
	},
	
	ipInput: {
		
		"& .MuiFormHelperText-root": {
			display: "flex",
			flexFlow: "row nowrap"
		}
	}
	
}), "Config");


const serverTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


export class Config extends Component {
	
	handleBannerSave = value => inSite.ws.sendRequest("application.config.set", { banner: value });
	
	handleIsDevModeChange = (_, isDevMode) => inSite.ws.sendRequest("application.config.set", { isDevMode });
	
	handleIsLimitToCampTokenChange = (_, isLimitToCampToken) => inSite.ws.sendRequest("application.config.set", { isLimitToCampToken });
	
	handleCampTokenSave = value => inSite.ws.sendRequest("application.config.set", { campTokenToLimitTo: value.trim() });
	
	handleIsLimitToIPChange = (_, isLimitToIP) => inSite.ws.sendRequest("application.config.set", { isLimitToIP });
	
	handleIPSave = value => inSite.ws.sendRequest("application.config.set", { ipToLimitTo: value.trim() });
	
	handleCurrentIpClick = (_, ip) => inSite.ws.sendRequest("application.config.set", { ipToLimitTo: ip });
	
	handleTimeZoneChange = (_, timeZone) => inSite.ws.sendRequest("application.config.set", { timeZone });
	
	
	render() {
		
		return (
			<div className={classes.root}>
				
				<Subscription publication="application.config">
					{(isInited, {
						banner = "",
						timeZone,
						isDevMode,
						isLimitToCampToken,
						campTokenToLimitTo,
						isLimitToIP,
						ipToLimitTo,
						currentRemoteAddress
					}) => isInited ? (
						<>
							
							<div className={classes.row}>
								<SaveableTextField
									label="Баннер"
									value={banner}
									multiline
									variant="outlined"
									onSave={this.handleBannerSave}
								/>
							</div>
							
							<div className={classes.row}>
								<Autocomplete
									value={timeZone}
									options={Intl.supportedValuesOf("timeZone")}
									renderInput={params => (
										<TextField
											label="Часовой пояс"
											variant="outlined"
											{...params}
											helperText={timeZone === serverTimeZone ? "Соответствует часовому поясу сервера" : `Часовой пояс сервера: ${serverTimeZone}`}
										/>
									)}
									onChange={this.handleTimeZoneChange}
								/>
							</div>
							
							<div className={classes.row}>
								<FormControlLabel
									label="Dev-доступ"
									control={(
										<Checkbox checked={isDevMode} color="primary" onChange={this.handleIsDevModeChange} />
									)}
								/>
								
								<div className={classes.indent}>
									
									<FormControlLabel
										label="Ограничить по Camp-токену"
										control={(
											<Checkbox checked={isLimitToCampToken} color="primary" onChange={this.handleIsLimitToCampTokenChange} />
										)}
										disabled={!isDevMode}
									/>
									
									<SaveableTextField
										className={classes.campTokenInput}
										value={campTokenToLimitTo}
										helperText={howmuch(campTokenToLimitTo.length, "символ", "символа", "символов")}
										size="small"
										onSave={this.handleCampTokenSave}
										disabled={!isDevMode || !isLimitToCampToken}
									/>
									
									<FormControlLabel
										label="Ограничить по IP"
										control={(
											<Checkbox checked={isLimitToIP} color="primary" onChange={this.handleIsLimitToIPChange} />
										)}
										disabled={!isDevMode}
									/>
									
									<SaveableTextField
										className={classes.ipInput}
										value={ipToLimitTo}
										helperText={isDevMode && isLimitToIP && (
											ipToLimitTo === currentRemoteAddress ?
												"Совпадает с текущим" :
												(
													<>
														Текущий IP:&nbsp;
														<HelperTextButton color="primary" onClick={event => this.handleCurrentIpClick(event, currentRemoteAddress)}>
															{currentRemoteAddress}
														</HelperTextButton>
													</>
												)
										)}
										onSave={this.handleIPSave}
										disabled={!isDevMode || !isLimitToIP}
									/>
								
								</div>
							</div>
						
						</>
					) : (
						<Loading />
					)}
				</Subscription>
			
			</div>
		);
	}
	
}
