import { Component } from "react";
import clsx from "clsx";
import Checkbox from "@material-ui/core/esm/Checkbox";
import Chip from "@material-ui/core/esm/Chip";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative"
	},
	
	org: {
		
		"& .MuiChip-label, & $optionLabel": {
			fontWeight: "bold"
		}
	},
	
	optionLabel: {},
	
	optionWrapper: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		
		"& .MuiCheckbox-root": {
			marginRight: -spacing(1)
		}
	}
	
}), "PermissionsInput");


export class PermissionsInput extends Component {
	
	#handleGetOptionLabel = option => option.displayLabel;
	
	#handleGroupBy = option => option.isUser ? "Люди" : "Организации";
	
	#handleRenderOption = (option, { selected }) => {
		const isInherited = this.props.inheritance?.some(anotherOption => anotherOption === option);
		
		return (
			<div className={clsx(classes.optionWrapper, option.isOrg && classes.org)}>
				<span className={classes.optionLabel}>
					{this.#handleGetOptionLabel(option)}
				</span>
				<Checkbox checked={selected} color="primary" disabled={isInherited} />
			</div>
		);
	};
	
	#handleRenderInput = params => {
		
		const { length } = this.props.value;
		
		return (
			<TextField label={`${this.props.label}${length ? ` (${length})` : ""}`} variant="outlined" {...params} />
		);
	};
	
	#handleRenderTags = (options, getTagProps) => options.map((option, index) => {
		const isInherited = this.props.inheritance?.some(anotherOption => anotherOption === option);
		
		const { key, ...props } = getTagProps({ index });
		props.label = this.#handleGetOptionLabel(option);
		props.variant = isInherited ? "outlined" : "default";
		if (isInherited)
			delete props.onDelete;
		if (option.isOrg)
			props.className = clsx(props.className, classes.org);
		
		return (
			<Chip key={key} {...props} />
		);
	});
	
	
	render() {
		
		const { className, options = [], value, without, onChange, ...restProps } = this.props;
		
		return (
			<div className={clsx(classes.root, className)}>
				<Autocomplete
					value={without ? value.without(without) : value}
					disableClearable
					disableCloseOnSelect
					getOptionLabel={this.#handleGetOptionLabel}
					groupBy={this.#handleGroupBy}
					multiple
					noOptionsText="Никого нет"
					options={without ? options.without(without) : options}
					renderInput={this.#handleRenderInput}
					renderOption={this.#handleRenderOption}
					renderTags={this.#handleRenderTags}
					onChange={onChange}
					{...restProps}
				/>
			</div>
		);
	}
	
}
