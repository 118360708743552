import { Component, Fragment } from "react";
import Checkbox from "@material-ui/core/esm/Checkbox";
import Chip from "@material-ui/core/esm/Chip";
import FormControlLabel from "@material-ui/core/esm/FormControlLabel";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { SaveableTextField } from "$components/SaveableTextField";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		
		"& $root": {
			paddingLeft: spacing(4)
		}
	},
	
	params: {
		display: "flex",
		flexFlow: "row wrap",
		paddingLeft: spacing(4),
		marginRight: -spacing(2),
		
		"& > *": {
			marginRight: spacing(1)
		}
	},
	
	paramNumber: {
		flex: `0 0 ${spacing(20)}px`,
		
		"& .MuiFormLabel-root": {
			whiteSpace: "nowrap"
		}
	},
	
	autocompleteWrapper: {
		flex: "1 0 auto",
		position: "relative",
		
		"& .MuiAutocomplete-inputRoot": {
			minHeight: spacing(3.75),
			
			"&[class*=\"MuiInput-root\"]": {
				paddingBottom: 0,
				
				"& .MuiAutocomplete-input": {
					padding: "inherit"
				}
			}
		},
		
		"& .MuiAutocomplete-popper": {
			marginTop: "-1.66em"/* helperText line-height */
		}
	}
	
}), "AbilitiesInput");


class ParamInput extends Component {
	
	#handleItemsGetOptionLabel = item => item.title;
	
	#handleNumberSave = value => {
		
		const { ability, param, onChange } = this.props;
		
		onChange(ability._id, param._id, value);
		
	};
	
	#handleItemsChange = (_, items) => {
		
		const { ability, param, onChange } = this.props;
		
		onChange(ability._id, param._id, items.ids());
		
	};
	
	
	render() {
		
		const { value, inheritedValue, param, disablePortal } = this.props;
		
		return param.type === "number" ? (
			<SaveableTextField
				className={classes.paramNumber}
				label={param.title}
				type="number"
				value={value?.[param._id] ?? ""}
				InputLabelProps={{ shrink: true }}
				inputProps={{ min: Math.max(param.min, inheritedValue?.[param._id] ?? param.min), max: param.max }}
				onSave={this.#handleNumberSave}
				disabled={!value}
			/>
		) : param.type === "items" ? (
			<div className={classes.autocompleteWrapper}>
				<Autocomplete
					value={(value?.[param._id] && param.items.trimTo(value[param._id])) ?? []}
					disableClearable
					disablePortal={disablePortal}
					filterSelectedOptions
					getOptionLabel={this.#handleItemsGetOptionLabel}
					multiple
					noOptionsText="Пусто"
					options={param.items}
					renderInput={props => {
						props.InputLabelProps.shrink = true;
						
						return (
							
							<TextField {...props} label={`${param.title}    `} helperText=" " />
						);
					}}
					renderTags={(tags, getTagProps) => tags.map((item, index) => {
						const props = getTagProps({ index });
						props.label = item.title;
						props.size = "small";
						
						if (inheritedValue?.[param._id]?.includes(item._id)) {
							delete props.onDelete;
							props.disabled = true;
						}
						
						return (
							<Chip key={item._id} {...props} />
						);
					})}
					onChange={this.#handleItemsChange}
					disabled={!value}
				/>
			</div>
		) : null;
	}
}


class Ability extends Component {
	
	#handleChange = (_, checked) => {
		
		const { ability } = this.props;
		
		this.props.onChange(ability._id, null, checked);
		
	};
	
	
	render() {
		
		const { ability, role, disabled, onChange, disablePortal } = this.props;
		
		const value = role.abilities[ability._id];
		const inheritedValue = role.inheritedAbilities[ability._id];
		
		return (
			<div className={classes.ability}>
				<FormControlLabel
					label={ability.title}
					control={(
						<Checkbox checked={!!value} color="primary" onChange={this.#handleChange} />
					)}
					disabled={disabled || !!(value && inheritedValue)}
				/>
				{ability.params && (
					<div className={classes.params}>
						{ability.params.map(param => (
							<ParamInput
								value={value}
								ability={ability}
								disablePortal={disablePortal}
								inheritedValue={inheritedValue}
								param={param}
								onChange={onChange}
								key={ability._id + param._id}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}


export function AbilitiesInput({ abilities, role, disabled, onChange, disablePortal }) {
	return (
		<div className={classes.root}>
			{abilities.map(ability => (
				<Fragment key={ability._id}>
					<Ability
						ability={ability}
						disablePortal={disablePortal}
						role={role}
						onChange={onChange}
						disabled={disabled}
					/>
					{ability.subAbilities && (
						<AbilitiesInput
							abilities={ability.subAbilities}
							disablePortal={disablePortal}
							role={role}
							onChange={onChange}
							disabled={disabled || !role.abilities[ability._id]}
						/>
					)}
				</Fragment>
			))}
		</div>
	);
}
