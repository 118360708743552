import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/esm/Button";
import Divider from "@material-ui/core/esm/Divider";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, palette, typography }) => ({
	
	root: {
		display: "flex",
		flexFlow: "row nowrap",
		marginBottom: spacing(5)
	},
	
	link: {
		minWidth: "unset",
		color: palette.text.disabled,
		textTransform: "unset",
		letterSpacing: "unset",
		paddingLeft: spacing(3),
		paddingRight: spacing(3),
		fontSize: typography.h5.fontSize,
		
		"&:not(:first-child)": {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		},
		
		"&:not(:last-child)": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		}
	},
	
	active: {
		color: palette.primary.main
	}
	
}), "SectionSubnav");


export function SectionSubnav({ links }) {
	return (
		<div className={classes.root}>
			{links.map(([ to, label ], i) => (
				<Fragment key={to}>
					<Button
						className={classes.link}
						activeClassName={classes.active}
						color="inherit"
						component={NavLink}
						to={to}
					>
						{label}
					</Button>
					{i < links.length - 1 && (
						<Divider flexItem orientation="vertical" />
					)}
				</Fragment>
			))}
		</div>
	);
}
