import { Component } from "react";
import clsx from "clsx";
import { ContextMenu } from "insite-client-react";
import IconButton from "@material-ui/core/esm/IconButton";
import Paper from "@material-ui/core/esm/Paper";
import MoreVertIcon from "@material-ui/icons/esm/MoreVert";
import { classes } from "./classes";
import { datumSymbol, SelectablePaperContext } from "./common";
import { SelectablePaperContainer } from "./Container";


export class SelectablePaper extends Component {
	
	#handleOpenMenuPointerDown = event => this.#menu.open({ event });
	
	#handleRef = node => node && (node[datumSymbol] = this.props.datum);
	
	#menu;
	#menuProps = {
		anchorOrigin: { vertical: "bottom", horizontal: "center" },
		transformOrigin: { vertical: "top", horizontal: "center" },
		disablePortal: true,
		ref: menu => (this.#menu = menu)
	};
	
	
	render() {
		
		const {
			datum,
			className,
			header,
			menu,
			children,
			...restProps
		} = this.props;
		
		const isSelected = this.context === datum;
		
		return (
			<Paper
				className={clsx(
					classes.item,
					className,
					isSelected && classes.selected
				)}
				elevation={isSelected ? 3 : 0}
				ref={this.#handleRef}
				{...restProps}
			>
				
				{isSelected && (
					<>
						<IconButton className={classes.menuButton} onPointerDown={this.#handleOpenMenuPointerDown}>
							<MoreVertIcon />
						</IconButton>
						
						{menu && (
							<ContextMenu {...this.#menuProps}>
								{menu}
							</ContextMenu>
						)}
					</>
				)}
				
				{children}
			
			</Paper>
		);
	}
	
	
	static contextType = SelectablePaperContext;
	
	static Container = SelectablePaperContainer;
	
}
