function getRandomUpperCase() {
	return String.fromCodePoint(Math.floor(Math.random() * 26) + 65);
}

function getRandomLowerCase() {
	return String.fromCodePoint(Math.floor(Math.random() * 26) + 97);
}

function getRandomNumber() {
	return String.fromCodePoint(Math.floor(Math.random() * 10) + 48);
}

const symbols = String.raw`!@#$%^&*()[]{}<>-_=+,.;:?\/|~`;
function getRandomSymbol() {
	return symbols[Math.floor(Math.random() * symbols.length)];
}

function shuffleString(string) {
	return string.split("").sort(() => .5 - Math.random()).join("");
}


export default function generatePassword(length = 16, digitsCount = 4, symbolsCount = 2) {
	let characters = "";
	
	for (let i = 0; i < digitsCount; i++)
		characters += getRandomNumber();
	length -= digitsCount;
	
	for (let i = 0; i < symbolsCount; i++)
		characters += getRandomSymbol();
	length -= symbolsCount;
	
	const lowerCase = length - Math.floor(length / 2);
	for (let i = 0; i < lowerCase; i++)
		characters += getRandomLowerCase();
	length -= lowerCase;
	
	for (let i = 0; i < length; i++)
		characters += getRandomUpperCase();
	
	return shuffleString(characters);
}
