import { Component } from "react";
import { clsx } from "clsx";
import { EditableAvatar } from "insite-client-react/Subject";
import { ContextMenuItem } from "insite-client-react";
import { Subscription } from "insite-subscriptions-react";
import Box from "@material-ui/core/esm/Box";
import Chip from "@material-ui/core/esm/Chip";
import FormControl from "@material-ui/core/esm/FormControl";
import IconButton from "@material-ui/core/esm/IconButton";
import InputLabel from "@material-ui/core/esm/InputLabel";
import TextField from "@material-ui/core/esm/TextField";
import Typography from "@material-ui/core/esm/Typography";
import DoneIcon from "@material-ui/icons/esm/Done";
import EditIcon from "@material-ui/icons/esm/Edit";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { inSite } from "$app";
import { RolesInput } from "$components/RolesInput";
import { SaveableTextField } from "$components/SaveableTextField";
import { SelectablePaper } from "$components/SelectablePaper";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, typography }) => ({
	
	root: {
		display: "flex",
		flexFlow: "column nowrap",
		
		"& .MuiInputBase-root": {
			fontSize: "inherit",
			color: "inherit"
		}
	},
	
	header: {
		display: "flex",
		flexFlow: "row nowrap"
	},
	
	avatar: {
		alignSelf: "flex-start",
		margin: [ 0, spacing(1.5), 0, -spacing(1) ]
	},
	
	name: {
		marginRight: spacing(1.5)
	},
	
	job: {
		textTransform: "lowercase",
		marginTop: -spacing(.5)
	},
	
	nameTextField: {
		flex: "1 1 auto",
		marginRight: spacing(1.5),
		fontSize: typography.h5.fontSize
	},
	
	emailForm: {
		display: "flex",
		marginBottom: spacing(1),
		paddingTop: spacing(2),
		
		"& .MuiTypography-root": {
			marginRight: spacing(1)
		},
		
		"& .MuiTextField-root": {
			width: spacing(30),
			marginTop: spacing(1)
		}
	},
	
	passwordForm: {
		display: "flex",
		marginBottom: spacing(1),
		paddingTop: spacing(2),
		
		"& .MuiTypography-root": {
			marginRight: spacing(1)
		},
		
		"& .MuiTextField-root": {
			width: spacing(25),
			marginTop: spacing(1),
			
			"&:first-child": {
				marginRight: spacing(1)
			}
		}
	},
	
	orgWrapper: {
		flex: "1 1 auto",
		marginRight: spacing(1.5)
	},
	
	org: {},
	
	orgOption: {
		fontWeight: "bold"
	},
	
	orgInput: {
		fontWeight: "bold"
	},
	
	jobInput: {
		flex: "1 1 auto"
		
	},
	
	roleSubOption: {
		
		"&:before": {
			content: "\"└\"",
			marginLeft: `calc(-${spacing(1)}px + ${spacing(1.75)}px * (var(--level) - 1))`,
			paddingRight: ".25em",
			opacity: .12
		}
	},
	
	activeSessions: {
		minWidth: spacing(6),
		alignSelf: "flex-start",
		display: "flex",
		flexFlow: "row wrap",
		marginTop: spacing(1.5),
		paddingTop: spacing(2.5),
		
		"& .MuiFormLabel-root": {
			whiteSpace: "nowrap"
		},
		
		"& .MuiChip-root": {
			
			"&:not(:last-child)": {
				marginRight: spacing(1)
			}
		}
	}
	
}), "Person");


class Session extends Component {
	
	#handleDelete = () =>
		confirm("Завершить сессию пользователя?") &&
		inSite.ws.sendRequest("users.people.destroy-session", this.props.session._id);
	
	
	render() {
		
		const { session } = this.props;
		
		return (
			<Chip label={session.remoteAddress} variant={session.isOnline ? "default" : "outlined"} onDelete={this.#handleDelete} />
		);
	}
}


export class Person extends Component {
	
	state = {
		isEmailEditing: false,
		isNewEmailOk: false,
		isPasswordEditing: false,
		newPassword: "",
		newPasswordRepeat: ""
	};
	
	sessionSubsriptionParams = [ this.props.person._id ];
	
	#handleFirstNameSave = firstName => inSite.ws.sendRequest("users.people.update", this.props.person._id, { name: { ...this.props.person.name, first: firstName } });
	#handleMiddleNameSave = middleName => inSite.ws.sendRequest("users.people.update", this.props.person._id, { name: { ...this.props.person.name, middle: middleName } });
	#handleLastNameSave = lastName => inSite.ws.sendRequest("users.people.update", this.props.person._id, { name: { ...this.props.person.name, last: lastName } });
	
	#handleEmailKeyDown = event => event.keyCode === 13 && this.#handleChangeEmailClick();
	
	#handleEmailBlur = event => !event.relatedTarget?.closest(`.${classes.emailForm}`) && this.setState({
		isEmailEditing: false,
		newEmail: "",
		isNewEmailOk: false
	});
	
	#handleNewEmailInput = ({ target: { value: newEmail } }) => {
		this.setState({ newEmail });
		
		inSite.ws.sendRequest("users.people.check-email", newEmail, error => this.setState({ isNewEmailOk: !error }));
		
	};
	
	#handleChangeEmailClick = async () => {
		
		if (this.state.isEmailEditing)
			await inSite.ws.sendRequest("users.people.update", this.props.person._id, { email: this.state.newEmail });
		
		this.setState({
			isEmailEditing: !this.state.isEmailEditing,
			newEmail: "",
			isNewEmailOk: false
		});
		
	};
	
	#handleRolesChange = roles => inSite.ws.sendRequest("users.people.update", this.props.person._id, { roles });
	
	#handleOrgGetOptionLabel = org => org.title;
	
	#handleOrgRenderInput = params => (
		<TextField {...params} label="Организация" />
	);
	
	#handleOrgChange = (_, org) => inSite.ws.sendRequest("users.people.update", this.props.person._id, { org: org && org._id });
	
	#handleJobSave = job => inSite.ws.sendRequest("users.people.update", this.props.person._id, { job });
	
	#handlePasswordKeyDown = event => event.keyCode === 13 && this.#handleChangePasswordClick();
	
	#handlePasswordBlur = event => !event.relatedTarget?.closest(`.${classes.passwordForm}`) && this.setState({
		isPasswordEditing: false,
		newPassword: "",
		newPasswordRepeat: ""
	});
	
	#handleNewPasswordInput = ({ target: { value: newPassword } }) => this.setState({ newPassword });
	#handleNewPasswordRepeatInput = ({ target: { value: newPasswordRepeat } }) => this.setState({ newPasswordRepeat });
	
	#handleChangePasswordClick = async () => {
		
		if (this.state.isPasswordEditing && this.state.newPassword && this.state.newPassword === this.state.newPasswordRepeat)
			await inSite.ws.sendRequest("users.people.change-password", this.props.person._id, this.state.newPassword);
		
		this.setState({
			isPasswordEditing: !this.state.isPasswordEditing,
			newPassword: "",
			newPasswordRepeat: ""
		});
		
	};
	
	#handleDeleteClick = () =>
		confirm(`Удалить пользователя ${this.props.person.displayLabel}?`) &&
		inSite.ws.sendRequest("users.people.delete", this.props.person._id);
	
	
	render() {
		
		const {
			className,
			person,
			host
		} = this.props;
		
		const {
			isEmailEditing,
			isPasswordEditing,
			newEmail,
			isNewEmailOk,
			newPassword,
			newPasswordRepeat
		} = this.state;
		
		const isSelected = this.context === person;
		
		return (
			<SelectablePaper
				className={clsx(classes.root, className)}
				datum={person}
				menu={(
					<ContextMenuItem onClick={this.#handleDeleteClick}>
						Удалить…
					</ContextMenuItem>
				)}
			>
				
				<div className={classes.header}>
					
					<EditableAvatar
						className={classes.avatar}
						dialog={host.avatarDialog}
						disablePortal
						editable={isSelected}
						person={person}
						ws={inSite.ws}
					/>
					
					{isSelected ? (
						<>
							<SaveableTextField
								className={classes.nameTextField}
								label="Имя"
								value={person.name.first}
								autoComplete="off"
								onSave={this.#handleFirstNameSave}
							/>
							<SaveableTextField
								className={classes.nameTextField}
								label="Отчество"
								value={person.name.middle}
								autoComplete="off"
								onSave={this.#handleMiddleNameSave}
							/>
							<SaveableTextField
								className={classes.nameTextField}
								label="Фамилия"
								value={person.name.last}
								autoComplete="off"
								onSave={this.#handleLastNameSave}
							/>
						</>
					) : (
						<Box
							display="flex"
							flexDirection="column"
							flexWrap="nowrap"
							justifyContent="center"
						>
							<Typography className={classes.name} component="div" variant="subtitle1">
								{person.displayLabel}
							</Typography>
							{person.job && (
								<Typography
									className={classes.job}
									color="textSecondary"
									component="div"
									variant="caption"
								>
									{person.job}
								</Typography>
							)}
						</Box>
					)}
				
				</div>
				
				{isSelected && (
					<FormControl className={classes.emailForm} onBlur={this.#handleEmailBlur} onKeyDown={this.#handleEmailKeyDown}>
						
						<InputLabel shrink>
							Электронная почта
						</InputLabel>
						
						<Box
							alignItems="center"
							display="flex"
							flexDirection="row"
							flexWrap="nowrap"
						>
							
							{isEmailEditing ? (
								<TextField
									label="Новая"
									type="email"
									value={newEmail}
									autoComplete="off"
									autoFocus
									onInput={this.#handleNewEmailInput}
								/>
							) : (
								<Typography variant="h5">
									{person.email}
								</Typography>
							)}
							
							<IconButton
								color="primary"
								size={isEmailEditing ? "medium" : "small"}
								onClick={this.#handleChangeEmailClick}
								disabled={isEmailEditing && !isNewEmailOk}
								key={isEmailEditing}
							>
								{isEmailEditing ? (
									<DoneIcon />
								) : (
									<EditIcon />
								)}
							</IconButton>
						
						</Box>
					
					</FormControl>
				)}
				
				{isSelected && (
					<FormControl className={classes.passwordForm} onBlur={this.#handlePasswordBlur} onKeyDown={this.#handlePasswordKeyDown}>
						
						<InputLabel shrink>
							Пароль
						</InputLabel>
						
						<Box
							alignItems="center"
							display="flex"
							flexDirection="row"
							flexWrap="nowrap"
						>
							{isPasswordEditing ? (
								<>
									<TextField
										className="password"
										label="Новый"
										type="password"
										value={newPassword}
										autoComplete="new-password"
										autoFocus
										onInput={this.#handleNewPasswordInput}
									/>
									<TextField
										className="repeat"
										label="Ещё раз"
										type="password"
										value={newPasswordRepeat}
										autoComplete="new-password"
										onInput={this.#handleNewPasswordRepeatInput}
									/>
								</>
							) : (
								<Typography variant="h5">
									••••••••••••
								</Typography>
							)}
							
							<IconButton
								color="primary"
								size={isPasswordEditing ? "medium" : "small"}
								onClick={this.#handleChangePasswordClick}
								disabled={isPasswordEditing && (!newPassword || newPassword !== newPasswordRepeat)}
								key={isPasswordEditing}
							>
								{isPasswordEditing ? (
									<DoneIcon />
								) : (
									<EditIcon />
								)}
							</IconButton>
						
						</Box>
					
					</FormControl>
				)}
				
				{isSelected && (
					<>
						
						<Box display="flex" flexDirection="row" flexWrap="nowrap">
							
							<div className={classes.orgWrapper}>
								<Autocomplete
									className={classes.org}
									classes={{ input: classes.orgInput, option: classes.orgOption }}
									value={person.org === inSite.orgs.null ? null : person.org}
									disablePortal
									getOptionLabel={this.#handleOrgGetOptionLabel}
									noOptionsText="Пусто"
									options={inSite.user.slaveOrgs}
									renderInput={this.#handleOrgRenderInput}
									onChange={this.#handleOrgChange}
								/>
							</div>
							
							<SaveableTextField
								className={classes.jobInput}
								label="Должность"
								value={person.job}
								onSave={this.#handleJobSave}
							/>
						
						</Box>
						
						<RolesInput
							className={classes.roles}
							label="Роли"
							value={person.roles}
							disablePortal
							notEmpty
							options={inSite.roles.sorted}
							onChange={this.#handleRolesChange}
						/>
						
						<Subscription map params={this.sessionSubsriptionParams} publication="users.people.sessions">
							{(_, sessions) => sessions.size ? (
								<FormControl className={classes.activeSessions}>
									<InputLabel shrink>
										Сессии
									</InputLabel>
									
									<div>
										{sessions.sorted.map(session => (
											<Session session={session} key={session._id} />
										))}
									</div>
								
								</FormControl>
							) : null}
						</Subscription>
					
					</>
				)}
			
			</SelectablePaper>
		);
	}
	
	
	static contextType = SelectablePaper.contextType;
	
}
