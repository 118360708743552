export default {
	
	"@import": "url(\"https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap\")",
	
	"html, body, #root": {
		height: "100%"
	},
	
	body: {
		margin: 0
	},
	
	img: {},
	
	"*": {
		boxSizing: "border-box"
	},
	
	a: {
		textDecoration: "none"
	},
	
	"#root": {}
	
};
