import { Component } from "react";
import clsx from "clsx";
import Checkbox from "@material-ui/core/esm/Checkbox";
import Chip from "@material-ui/core/esm/Chip";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { inSite } from "$app";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative",
		
		"& .MuiChip-label": {
			fontWeight: "bold"
		}
	},
	
	subOption: {
		
		"&:before": {
			content: "\"└\"",
			marginLeft: `calc(-${spacing(1)}px + ${spacing(1.75)}px * (var(--level) - 1))`,
			paddingRight: ".25em",
			opacity: .12
		}
	},
	
	optionWrapper: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		
		"& .MuiCheckbox-root": {
			marginRight: -spacing(1)
		}
	},
	
	optionLabel: {
		flex: "1 1 auto",
		fontWeight: "bold"
	}
	
}), "SlaveOrgsInput");


export class SlaveOrgsInput extends Component {
	
	#handleGetOptionLabel = org => org.title;
	
	#handleRenderOption = (anotherOrg, { selected }) => (
		<div className={clsx(classes.optionWrapper, anotherOrg._l && classes.subOption)} style={{ "--level": anotherOrg._l }}>
			<span className={classes.optionLabel}>
				{this.#handleGetOptionLabel(anotherOrg)}
			</span>
			{anotherOrg !== this.props.org && (
				<Checkbox checked={selected} color="primary" />
			)}
		</div>
	);
	
	#handleRenderInput = params => {
		
		const { org: { slaveOrgs: { length } }, InputProps } = this.props;
		
		return (
			<TextField
				{...params}
				label={`Подчинённые организации${length ? ` (${length})` : ""}`}
				variant="outlined"
				{...InputProps}
			/>
		);
	};
	
	#handleRenderTags = (value, getTagProps) => {
		
		const { org } = this.props;
		
		return value.map((slaveOrg, index) => {
			const props = getTagProps({ index });
			
			return slaveOrg.owners.includes(org) ? (
				<Chip {...props} label={slaveOrg.title} key={slaveOrg._id} />
			) : (
				<Chip
					className={props.className}
					label={slaveOrg.title}
					size="small"
					variant="outlined"
					key={slaveOrg._id}
				/>
			);
		});
	};
	
	#handleChange = async (_, __, action, { option: slaveOrg }) => {
		
		const { org } = this.props;
		const owners = slaveOrg.owners.ids();
		
		if (action === "select-option")
			owners.push(org._id);
		else
			owners.remove(org._id);
		
		await inSite.ws.sendRequest("users.orgs.update", slaveOrg._id, { owners });
		
	};
	
	#handleGetOptionDisabled = anotherOrg => {
		
		const { org } = this.props;
		
		return (
			anotherOrg === org ||
			anotherOrg.slaveOrgs.includes(org) ||
			(org.slaveOrgs.includes(anotherOrg) && !anotherOrg.owners.includes(org))
		);
	};
	
	
	render() {
		
		const { className, org, InputProps, ...restProps } = this.props;
		
		return (
			<div className={clsx(classes.root, className)}>
				<Autocomplete
					value={org.slaveOrgs}
					disableClearable
					disableCloseOnSelect
					getOptionDisabled={this.#handleGetOptionDisabled}
					getOptionLabel={this.#handleGetOptionLabel}
					multiple
					noOptionsText="Пусто"
					options={inSite.orgs.sortedHierarchically}
					renderInput={this.#handleRenderInput}
					renderOption={this.#handleRenderOption}
					renderTags={this.#handleRenderTags}
					onChange={this.#handleChange}
					{...restProps}
				/>
			</div>
		);
	}
}
