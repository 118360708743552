import clsx from "clsx";
import { Subscription } from "insite-subscriptions-react";
import Typography from "@material-ui/core/esm/Typography";
import { inSite } from "$app";
import { Loading } from "$components/Loading";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, palette }) => ({
	
	root: {
		position: "relative",
		flex: "1 0 auto",
		display: "flex",
		flexFlow: "column nowrap",
		paddingRight: spacing(7)
	},
	
	list: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "column nowrap",
		gap: spacing(3)
	},
	
	item: {
		flex: "0 0 auto",
		display: "flex",
		flexFlow: "column nowrap"
	},
	
	itemTop: {
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center"
	},
	
	itemCreatedAt: {
		flex: "0 0 auto",
		width: spacing(17),
		whiteSpace: "nowrap"
	},
	
	itemUser: {
		flex: "0 0 auto",
		width: spacing(35),
		whiteSpace: "nowrap"
	},
	
	itemSource: {
		flex: "0 0 auto",
		width: spacing(25),
		whiteSpace: "nowrap"
	},
	
	itemProjectTitle: {},
	
	itemMessage: {
		whiteSpace: "pre-wrap",
		paddingLeft: spacing(2),
		borderLeft: `${spacing(.25)}px solid`,
		fontFamily: "monospace"
	},
	
	error: {
		borderLeftColor: palette.error.main
	}
	
}), "Logs");


const intlFormatter = new Intl.DateTimeFormat("ru-RU", { dateStyle: "short", timeStyle: "medium" });


export function Logs() {
	return (
		<div className={classes.root}>
			
			<div className={classes.list}>
				<Subscription map publication="application.logs">
					{(isInited, logs) => isInited ? logs.sorted.map(logItem => (
						<div className={classes.item} key={logItem._id}>
							<div className={classes.itemTop}>
								<Typography className={classes.itemCreatedAt} color="textSecondary" variant="caption">
									{intlFormatter.format(logItem.createdAt)}
								</Typography>
								<Typography className={classes.itemUser} color="textSecondary" variant="overline">
									{logItem.userId && inSite.users.get(logItem.userId).displayLabel}
								</Typography>
								<Typography className={classes.itemSource} color="textSecondary" variant="overline">
									{logItem.source}
								</Typography>
								{logItem.projectTitle && (
									<Typography
										className={classes.itemProjectTitle}
										color="textSecondary"
										title={logItem.projectId}
										variant="overline"
									>
										{logItem.projectTitle}
									</Typography>
								)}
							</div>
							
							<Typography className={clsx(classes.itemMessage, classes[logItem.type])}>
								{logItem.message}
							</Typography>
						
						</div>
					)) : (
						<Loading />
					)}
				</Subscription>
			</div>
		
		</div>
	);
}
