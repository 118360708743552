import { Component } from "react";
import clsx from "clsx";
import Fab from "@material-ui/core/esm/Fab";
import AddIcon from "@material-ui/icons/esm/Add";
import { classes } from "./classes";
import { datumSymbol, SelectablePaperContext } from "./common";


export class SelectablePaperContainer extends Component {
	
	state = {
		selected: null
	};
	
	#handleClick = ({ target }) => {
		const datum = target.closest(`.${classes.item}`)?.[datumSymbol];
		if (datum && this.state.selected !== datum) {
			this.setState({ selected: datum });
			window.addEventListener("pointerdown", this.#handleWindowClick);
		}
		
	};
	
	#handleWindowClick = ({ target }) => {
		if (this.state.selected && !target.closest(`.${classes.item}`)) {
			this.setState({ selected: null });
			window.removeEventListener("pointerdown", this.#handleWindowClick);
		}
		
	};
	
	#handleAddClick = () => this.addDialog.open();
	
	#handleAddDialogRef = dialog => (this.addDialog = dialog);
	
	
	render() {
		
		const { className, children, AddDialog, AddDialogProps, ...restProps } = this.props;
		const { selected } = this.state;
		
		return (
			<div className={clsx(classes.root, className)}>
				
				<div className={classes.list} onClick={this.#handleClick} {...restProps}>
					<SelectablePaperContext.Provider value={selected}>
						{children}
					</SelectablePaperContext.Provider>
				</div>
				
				{AddDialog && (
					<>
						<Fab className={classes.addButton} color="primary" onClick={this.#handleAddClick}>
							<AddIcon fontSize="large" />
						</Fab>
						<AddDialog ref={this.#handleAddDialogRef} {...AddDialogProps} />
					</>
				)}
			
			</div>
		);
	}
	
	componentWillUnmount() {
		
		window.removeEventListener("click", this.#handleWindowClick);
		
	}
	
}
