import { Component } from "react";
import clsx from "clsx";
import { ContextMenuItem } from "insite-client-react";
import Chip from "@material-ui/core/esm/Chip";
import FormLabel from "@material-ui/core/esm/FormLabel";
import Typography from "@material-ui/core/esm/Typography";
import { inSite } from "$app";
import { RolesInput } from "$components/RolesInput";
import { SaveableTextField } from "$components/SaveableTextField";
import { SelectablePaper } from "$components/SelectablePaper";
import { createStyles } from "$styles";
import { AbilitiesInput } from "./AbilitiesInput";


const classes = createStyles(({ spacing, palette, typography }) => ({
	
	root: {
		
		"& .MuiInputBase-root": {
			fontSize: "inherit",
			color: "inherit"
		}
	},
	
	slave: {
		marginLeft: `calc(-${spacing(2.5)}px + ${spacing(1.75)}px * var(--level))`
	},
	
	header: {
		display: "flex",
		flexFlow: "row nowrap",
		paddingRight: spacing(4),
		
		"$slave &:before": {
			content: "\"└\"",
			width: spacing(2.5),
			opacity: .12
		}
	},
	
	title: {
		alignSelf: "center",
		marginRight: spacing(1.5),
		fontSize: typography.h5.fontSize
	},
	
	titleTextField: {
		flex: "1 1 auto",
		marginRight: spacing(1.5),
		fontSize: typography.h5.fontSize
	},
	
	id: {
		fontSize: typography.h5.fontSize,
		marginTop: spacing(2)
	},
	
	details: {},
	
	description: {
		fontSize: typography.body2.fontSize,
		color: palette.text.secondary,
		whiteSpace: "pre-wrap",
		marginTop: spacing(1)
	},
	
	descriptionTextField: {
		fontSize: typography.body2.fontSize,
		color: palette.text.secondary,
		marginTop: spacing(.5)
	},
	
	involves: {
		marginTop: spacing(1),
		
		"& > *": {
			
			"&:not(:last-child)": {
				marginRight: spacing(.75)
			}
		}
	},
	
	involvesAutocomplete: {
		margin: [ spacing(1), 0, spacing(2) ]
	}
	
}), "Role");


export class Role extends Component {
	
	#handleTitleSave = title => inSite.ws.sendRequest("users.roles.update", this.props.role._id, { title });
	
	#handleDescriptionSave = description => inSite.ws.sendRequest("users.roles.update", this.props.role._id, { description });
	
	#handleInvolvesChange = involves => inSite.ws.sendRequest("users.roles.update", this.props.role._id, { involves });
	
	#handleAbilitiesChange = (abilityId, paramId, value) => inSite.ws.sendRequest("users.roles.set-ability", this.props.role._id, abilityId, paramId, value);
	
	#handleDeleteClick = () =>
		confirm(`Удалить роль ${this.props.role.displayTitle}?`) &&
		inSite.ws.sendMessage("users.roles.delete", this.props.role._id);
	
	
	render() {
		
		const { role, abilities } = this.props;
		
		const isSelected = this.context === role;
		
		return (
			<div
				className={clsx(
					classes.root,
					role._l && classes.slave
				)}
				style={{ "--level": role._l }}
			>
				<SelectablePaper
					datum={role}
					menu={(
						<ContextMenuItem onClick={this.#handleDeleteClick}>
							Удалить…
						</ContextMenuItem>
					)}
				>
					
					<div className={classes.header}>
						{isSelected ? (
							<>
								<SaveableTextField
									className={classes.titleTextField}
									label="Название"
									value={role.title}
									autoComplete="off"
									onSave={this.#handleTitleSave}
								/>
								<Chip className={classes.id} label={role._id} variant="outlined" />
							</>
						) : (
							<Typography className={classes.title}>
								{role.displayTitle}
							</Typography>
						)}
					</div>
					
					<div className={classes.details}>
						
						{isSelected ? (
							<SaveableTextField
								className={classes.descriptionTextField}
								label="Описание"
								value={role.description}
								fullWidth
								multiline
								onSave={this.#handleDescriptionSave}
							/>
						) : role.description && (
							<Typography className={classes.description}>
								{role.description}
							</Typography>
						)}
						
						{isSelected && (
							<>
								
								<RolesInput
									className={classes.involvesAutocomplete}
									label="Включает в себя и наследует возможности"
									value={role.ownInvolves}
									disablePortal
									exclude={[ role ]}
									options={inSite.roles.sorted}
									onChange={this.#handleInvolvesChange}
								/>
								
								<FormLabel>
									Возможности
								</FormLabel>
								
								<AbilitiesInput
									abilities={abilities}
									disablePortal
									role={role}
									onChange={this.#handleAbilitiesChange}
								/>
							
							</>
						)}
					
					</div>
				
				</SelectablePaper>
			</div>
		);
	}
	
	
	static contextType = SelectablePaper.contextType;
	
}
