import { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Subject } from "insite-client-react/Subject";
import LogoutIcon from "@nesvet/missing-mui4-components/icons/Logout";
import Button from "@material-ui/core/esm/Button";
import { inSite } from "$app";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, palette, typography }) => ({
	
	root: {
		flex: "0 0 auto",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		width: spacing(35),
		padding: [ spacing(6), spacing(3) ]
	},
	
	user: {
		marginBottom: spacing(4)
	},
	
	logout: {
		color: palette.text.secondary
	},
	
	tabs: {
		alignSelf: "stretch",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "stretch"
	},
	
	tab: {
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		justifyContent: "flex-start",
		marginTop: spacing(2),
		padding: [ spacing(1.5), spacing(3) ],
		color: palette.text.disabled,
		fontSize: typography.h5.fontSize,
		fontWeight: "unset",
		letterSpacing: "unset",
		textTransform: "unset",
		
		"& .MuiButton-startIcon": {
			marginRight: spacing(2),
			
			"& .MuiSvgIcon-root": {
				width: spacing(4),
				height: spacing(4)
			}
		}
	},
	
	currentTab: {
		color: palette.primary.main
	}
	
}), "SideBar");


function SectionTab({ Section: { sectionName, Icon, label } }) {
	return (
		<Button
			className={classes.tab}
			activeClassName={classes.currentTab}
			color="inherit"
			component={NavLink}
			startIcon={(
				<Icon />
			)}
			to={`/${sectionName}`}
		>
			{label}
		</Button>
	);
}


class SideBarComponent extends Component {
	
	#handleLogoutClick = () => inSite.ws.sendRequest("logout");
	
	
	render() {
		
		const { sections } = this.props;
		
		return (
			<div className={classes.root}>
				
				<Subject
					className={classes.user}
					for={inSite.user}
					size="l"
					vertical
					online={false}
				>
					<Button className={classes.logout} onClick={this.#handleLogoutClick}>
						<LogoutIcon />
					</Button>
				</Subject>
				
				<div className={classes.tabs}>
					{sections.map(Section => (
						<SectionTab Section={Section} key={Section.sectionName} />
					))}
				</div>
			
			</div>
		);
	}
	
}


export const SideBar = withRouter(SideBarComponent);
