import { Component } from "react";
import clsx from "clsx";
import { AvatarDialog } from "insite-client-react/Subject";
import Typography from "@material-ui/core/esm/Typography";
import { inSite } from "$app";
import { SelectablePaper } from "$components/SelectablePaper";
import { createStyles } from "$styles";
import { AddPersonDialog } from "./AddDialog";
import { Person } from "./Person";


const classes = createStyles(({ spacing, palette }) => ({
	
	org: {
		
		"&:not(:last-child)": {
			marginBottom: spacing(8)
		}
	},
	
	orgTitle: {
		display: "block",
		margin: [ 0, 0, spacing(4), spacing(-2) ],
		color: palette.text.hint
	},
	
	nullOrg: {
		
		"& $orgTitle": {
			fontStyle: "italic"
		}
	},
	
	person: {
		maxWidth: spacing(100),
		
		"&:not(:last-child)": {
			marginBottom: spacing(3)
		}
	}
	
}), "People");


function OrgGrouping({ org, onPersonClick, host }) {
	return (
		<div
			className={clsx(
				classes.org,
				(org === inSite.orgs.null) && classes.nullOrg
			)}
		>
			<Typography className={classes.orgTitle} variant="overline">
				{org.title || "Без организации"}
			</Typography>
			{org.users.sorted.map(person => (
				<Person
					className={classes.person}
					host={host}
					person={person}
					onClick={onPersonClick}
					key={person._id}
				/>
			))}
		</div>
	);
}


export class People extends Component {
	
	#handleAvatarDialogRef = dialog => (this.avatarDialog = dialog);
	
	
	render() {
		
		return (
			<>
				<SelectablePaper.Container AddDialog={AddPersonDialog}>
					{[ ...inSite.orgs.sorted, inSite.orgs.null ].map(org => org.users.size ? (
						<OrgGrouping
							host={this}
							org={org}
							key={org._id}
						/>
					) : null)}
				</SelectablePaper.Container>
				
				<AvatarDialog transferKind="users.people.avatar" ws={inSite.ws} ref={this.#handleAvatarDialogRef} />
			</>
		);
	}
	
}
