import Dialog from "@nesvet/missing-mui4-components/Dialog";
import LoadingButton from "@nesvet/missing-mui4-components/LoadingButton";
import TextField from "@material-ui/core/esm/TextField";
import DoneIcon from "@material-ui/icons/esm/Done";
import { inSite } from "$app";
import { HelperTextIcon } from "$components/HelperTextIcon";


export class AddRoleDialog extends Dialog {
	constructor(props) {
		super(props, {
			id: "",
			idError: null,
			isPending: false
		});
		
	}
	
	title = "Новая роль";
	
	maxWidth = "xs";
	
	#idErrorMessages = {
		empty: "Нужно заполнить",
		notroleid: "Не похоже на id",
		exists: "Такая роль уже есть",
		unknown: "Неизвестная ошибка"
	};
	
	#handleKeyDown = event =>
		event.keyCode === 13 &&
		!this.state.idError &&
		!event.preventDefault() &&
		this.#handleAddClick();
	
	#handleIdInput = ({ target: { value: id } }) => {
		const state = { id };
		
		if (id)
			inSite.ws.sendRequest("users.roles.check-id", id, error => this.setState({ idError: error?.tag ?? null }));
		else
			state.idError = "empty";
		
		this.setState(state);
		
	};
	
	#handleAddClick = async () => {
		
		this.setState({ isPending: true });
		
		try {
			const { id } = this.state;
			
			await inSite.ws.sendRequest("users.roles.add", { _id: id });
			
			this.setState({ isPending: false });
			
			this.close();
		} catch (error) {
			this.setState({
				idError: error.tag in this.#idErrorMessages ? error.tag : "unknown",
				isPending: false
			});
		}
		
	};
	
	
	renderContent() {
		
		const {
			id,
			idError
		} = this.state;
		
		return (
			<TextField
				label="id"
				value={id}
				autoComplete="off"
				autoFocus
				error={!!idError}
				fullWidth
				helperText={idError ? this.#idErrorMessages[idError] : id ? (
					<HelperTextIcon color="primary" Icon={DoneIcon} />
				) : " "}
				onInput={this.#handleIdInput}
				onKeyDown={this.#handleKeyDown}
			/>
		);
	}
	
	renderActions() {
		
		const {
			id,
			idError,
			isPending
		} = this.state;
		
		return (
			<LoadingButton
				color="primary"
				disableElevation
				pending={isPending}
				variant="contained"
				onClick={this.#handleAddClick}
				disabled={!id || !!idError}
			>
				Добавить
			</LoadingButton>
		);
	}
	
}
