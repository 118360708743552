import { Component } from "react";
import clsx from "clsx";
import Checkbox from "@material-ui/core/esm/Checkbox";
import Chip from "@material-ui/core/esm/Chip";
import TextField from "@material-ui/core/esm/TextField";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative"
	},
	
	subOption: {
		
		"&:before": {
			content: "\"└\"",
			marginLeft: `calc(-${spacing(1)}px + ${spacing(1.75)}px * (var(--level) - 1))`,
			paddingRight: ".25em",
			opacity: .12
		}
	},
	
	optionWrapper: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		
		"& .MuiCheckbox-root": {
			marginRight: -spacing(1)
		}
	},
	
	optionLabel: {
		flex: "1 1 auto"
	}
	
}), "RolesInput");


export class RolesInput extends Component {
	
	#handleGetOptionLabel = role => role.displayTitle;
	
	#handleRenderOption = (role, { selected }) => {
		
		const { value, exclude } = this.props;
		
		const isInherited = value.some(anotherRole => anotherRole.involves.includes(role));
		
		return (
			<div className={clsx(classes.optionWrapper, role._l && classes.subOption)} style={{ "--level": role._l }}>
				<span className={classes.optionLabel}>
					{this.#handleGetOptionLabel(role)}
				</span>
				{!exclude?.includes(role) && (
					<Checkbox checked={selected || isInherited} color="primary" disabled={isInherited} />
				)}
			</div>
		);
	};
	
	#handleRenderInput = params => (
		<TextField
			{...params}
			label={this.props.label}
			variant="outlined"
			{...this.props.InputProps}
		/>
	);
	
	#handleRenderTags = (value, getTagProps) => value.reduce((chips, role, index) => {
		const props = getTagProps({ index });
		
		if (value.length === 1 && this.props.notEmpty)
			delete props.onDelete;
		
		chips.push(
			<Chip {...props} label={role.displayTitle} key={role._id} />
		);
		
		for (const involvedRole of role.involves)
			if (!value.includes(involvedRole) && !value.slice(0, index).some(prevRole => prevRole.involves.includes(involvedRole)))
				chips.push(
					<Chip
						className={props.className}
						label={involvedRole.displayTitle}
						size="small"
						variant="outlined"
						key={involvedRole._id}
					/>
				);
		
		return chips;
	}, []);
	
	#handleChange = (_, involves) => {
		
		const roleIds = [];
		
		for (let i = 0, { length } = involves; i < length;) {
			const role = involves[i++];
			if (!role.involves.includesAny(involves.slice(i)))
				roleIds.push(role._id);
		}
		
		if (roleIds.length || !this.props.notEmpty)
			this.props.onChange(roleIds);
		
	};
	
	#handleGetOptionDisabled = role => this.props.exclude?.includesAny([ role, ...role.involves ]);
	
	
	render() {
		
		const { className, options, value, notEmpty, exclude, label, onChange, InputProps, ...rest } = this.props;
		
		return (
			<div className={clsx(classes.root, className)}>
				<Autocomplete
					value={value}
					disableClearable
					getOptionDisabled={exclude ? this.#handleGetOptionDisabled : null}
					getOptionLabel={this.#handleGetOptionLabel}
					multiple
					noOptionsText="Пусто"
					options={options}
					renderInput={this.#handleRenderInput}
					renderOption={this.#handleRenderOption}
					renderTags={this.#handleRenderTags}
					onChange={this.#handleChange}
					disabled={!value}
					{...rest}
				/>
			</div>
		);
	}
}
