import { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/esm/Typography";
import SettingsApplicationsIcon from "@material-ui/icons/esm/SettingsApplications";
import { SectionSubnav } from "$components/SectionSubnav";
import { createStyles } from "$styles";
import { Config } from "./Config";
import { Logs } from "./Logs";
import { Releases } from "./Releases";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative",
		display: "flex",
		flexFlow: "column nowrap",
		padding: spacing(6)
	},
	
	header: {
		marginBottom: spacing(5)
	}
	
}), "Application");


export class Application extends Component {
	static sectionName = "application";
	static label = "Приложение";
	static Icon = SettingsApplicationsIcon;
	
	
	render() {
		
		const { className } = this.props;
		
		return (
			<section className={clsx(classes.root, className)}>
				
				<Typography className={classes.header} variant="h1">
					<span>
						Приложение
					</span>
				</Typography>
				
				<SectionSubnav
					links={[
						[ "/application/releases", "Релизы" ],
						[ "/application/config", "Настройки" ],
						[ "/application/logs", "Логи" ]
					]}
				/>
				
				<Switch>
					<Route exact path="/application">
						<Redirect to="/application/releases" />
					</Route>
					<Route path="/application/releases">
						<Releases />
					</Route>
					<Route path="/application/config">
						<Config />
					</Route>
					<Route path="/application/logs">
						<Logs />
					</Route>
				</Switch>
			
			</section>
		);
	}
	
}
