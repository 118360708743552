import { Component } from "react";
import clsx from "clsx";
import Collapse from "@nesvet/missing-mui4-components/Collapse@next";
import TextField from "@material-ui/core/esm/TextField";
import DoneAllIcon from "@material-ui/icons/esm/DoneAll";
import { createStyles } from "$styles";
import { HelperTextButton } from "../HelperTextButton";
import { HelperTextIcon } from "../HelperTextIcon";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		minWidth: spacing(17),
		
		"& .MuiFormHelperText-root": {
			display: "flex",
			flexFlow: "column nowrap",
			alignSelf: "stretch",
			opacity: .7,
			
			"& .MuiCollapse-wrapperInner": {
				display: "flex",
				flexFlow: "row nowrap",
				alignItems: "center"
			}
		}
	}
	
}), "SaveableTextField");


export class SaveableTextField extends Component {
	
	state = {
		value: this.props.value ?? "",
		status: null
	};
	
	#handleInput = event => {
		this.props.onInput?.(event);
		
		clearTimeout(this.savedTimeout);
		
		const { value } = event.target;
		
		this.setState({ value, status: this.props.value === value ? null : "unsaved" });
		
	};
	
	#handleKeyDown = event => {
		this.props.onKeyDown?.(event);
		
		if (event.keyCode === 13 && (!this.props.multiline || (event.ctrlKey || event.metaKey || event.shiftKey) && !event.preventDefault()))
			this.#handleSave();
		
	};
	
	#handleBlur = event => {
		this.props.onBlur?.(event);
		
		this.#handleSave();
		
	};
	
	#handleSave = async () => {
		if (this.state.status === "unsaved" && await this.props.onSave(this.state.value) !== false) {
			this.setState({ status: "saved" });
			this.savedTimeout = setTimeout(() => this.setState({ status: null }), 2000);
		}
		
	};
	
	#formHelperTextProps = { component: "div" };
	
	
	render() {
		
		const { className, onKeyDown, onInput, onBlur, defaultValue, value, onSave, number, ...rest } = this.props;
		const { status } = this.state;
		
		return (
			<TextField
				className={clsx(classes.root, className)}
				value={this.state.value}
				FormHelperTextProps={this.#formHelperTextProps}
				helperText={(
					<>
						<Collapse in={status === "saved"}>
							<HelperTextIcon color="primary" Icon={DoneAllIcon} mr />
							<span>
								Сохранено
							</span>
						</Collapse>
						<Collapse in={status === "unsaved"}>
							<span>
								Изменено.
							</span>
							<HelperTextButton tabIndex="-1">
								Сохранить
							</HelperTextButton>
						</Collapse>
						<Collapse in={!status}>
						&nbsp;
						</Collapse>
					</>
				)}
				onBlur={this.#handleBlur}
				onInput={this.#handleInput}
				onKeyDown={this.#handleKeyDown}
				{...rest}
			/>
		);
	}
	
	componentDidUpdate(prevProps) {
		
		if (this.props.value !== prevProps.value && this.props.value !== this.state.value)
			this.setState({ value: this.props.value, status: null });
		
	}
	
	componentWillUnmount() {
		
		clearTimeout(this.savedTimeout);
		
	}
	
}
