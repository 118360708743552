import { createStyles } from "$styles";


export const classes = createStyles(({ spacing, palette, shape, transitions }) => ({
	
	root: {
		flex: "1 0 auto",
		position: "relative",
		display: "flex",
		flexFlow: "column nowrap",
		paddingRight: spacing(9)
	},
	
	list: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "column nowrap",
		gap: spacing(3)
	},
	
	addButton: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1
	},
	
	item: {
		position: "relative",
		marginLeft: -spacing(3),
		padding: [ 0, spacing(3) ],
		backgroundColor: "transparent",
		borderRadius: shape.borderRadius * 2,
		transitionProperty: "background-color, box-shadow, padding",
		transitionDuration: transitions.duration.shortest,
		
		"&:not($selected)": {
			cursor: "pointer",
			
			"& > *": {
				userSelect: "none",
				pointerEvents: "none"
			}
		}
	},
	
	selected: {
		paddingBlock: spacing(3),
		backgroundColor: palette.background.paper
	},
	
	menuButton: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1,
		
		"&, & .MuiTouchRipple-child": {
			borderRadius: shape.borderRadius * 2,
			borderTopLeftRadius: 0,
			borderBottomRightRadius: 0
		}
	}
	
}), "SelectablePaper");
