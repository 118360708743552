import clsx from "clsx";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		fontSize: "1.66em"/* Default MuiFormHelperText-root line-height */
	},
	
	marginLeft: {
		marginLeft: spacing(.5)
	},
	
	marginRight: {
		marginRight: spacing(.5)
	}
	
}), "HelperTextIcon");


export function HelperTextIcon({ className, Icon, ml, mr, ...rest }) {
	return (
		<Icon className={clsx(classes.root, ml && classes.marginLeft, mr && classes.marginRight, className)} {...rest} />
	);
}
