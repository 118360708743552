import { Subscription } from "insite-subscriptions-react";
import { inSite } from "$app";
import { SelectablePaper } from "$components/SelectablePaper";
import { AddRoleDialog } from "./AddDialog";
import { Role } from "./Role";


export function Roles() {
	return (
		<SelectablePaper.Container AddDialog={AddRoleDialog}>
			<Subscription publication="abilities">
				{(isActive, { abilities }) => isActive ? inSite.roles.sorted.map(role => (
					<Role
						abilities={abilities}
						role={role}
						key={role._id}
					/>
				)) : null}
			</Subscription>
		</SelectablePaper.Container>
	);
}
