import CircularProgress from "@material-ui/core/esm/CircularProgress";
import { createStyles } from "$styles";


const classes = createStyles({
	
	root: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}
	
}, "Loading");


export function Loading() {
	return (
		<div className={classes.root}>
			<CircularProgress />
		</div>
	);
}
