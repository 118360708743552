import Dialog from "@nesvet/missing-mui4-components/Dialog";
import LoadingButton from "@nesvet/missing-mui4-components/LoadingButton";
import TextField from "@material-ui/core/esm/TextField";
import DoneIcon from "@material-ui/icons/esm/Done";
import { inSite } from "$app";
import { HelperTextIcon } from "$components/HelperTextIcon";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing }) => ({
	
	title: {
		width: spacing(50)
	}
	
}), "AddOrgDialog");


export class AddOrgDialog extends Dialog {
	constructor(props) {
		super(props, {
			title: "",
			titleError: null,
			isTitleOk: false,
			isPending: false
		});
		
	}
	
	title = "Новая организация";
	
	#titleErrorMessages = {
		empty: "Нужно заполнить",
		unknown: "Неизвестная ошибка"
	};
	
	#handleTitleInput = ({ target: { value: title } }) => this.setState({ title, titleError: title ? null : "empty", isTitleOk: !!title });
	
	#handleKeyDown = event =>
		event.keyCode === 13 &&
		!this.state.titleError &&
		!event.preventDefault() &&
		this.#handleAddClick();
	
	#handleAddClick = async () => {
		
		this.setState({ isPending: true });
		
		try {
			const { title } = this.state;
			
			await inSite.ws.sendRequest("users.orgs.add", { title });
			
			this.setState({ isPending: false });
			
			this.close();
		} catch (error) {
			this.setState({
				keyError: error.tag in this.#titleErrorMessages ? error.tag : "unknown",
				isPending: false
			});
		}
		
	};
	
	
	renderContent() {
		
		const { title, titleError, isTitleOk } = this.state;
		
		return (
			<TextField
				className={classes.title}
				label="Название"
				value={title}
				autoComplete="off"
				autoFocus
				error={!!titleError}
				helperText={titleError ? this.#titleErrorMessages[titleError] : isTitleOk ? (
					<HelperTextIcon color="primary" Icon={DoneIcon} />
				) : " "}
				onInput={this.#handleTitleInput}
				onKeyDown={this.#handleKeyDown}
			/>
		);
	}
	
	renderActions() {
		
		const {
			isTitleOk,
			isPending
		} = this.state;
		
		return (
			<LoadingButton
				color="primary"
				disableElevation
				pending={isPending}
				variant="contained"
				onClick={this.#handleAddClick}
				disabled={!isTitleOk}
			>
				Добавить
			</LoadingButton>
		);
	}
	
}
