import { Component } from "react";
import clsx from "clsx";


export class Dropzone extends Component {
	
	state = { isDropReady: false };
	
	handleDragStart = event => {
		event.stopPropagation();
		event.dataTransfer.effectAllowed = this.props.effect || "copy";
		
	};
	
	handleDragEnter = event => {
		this.setState({ isDropReady: true });
		this.props.onDragEnter?.(event);
		
	};
	
	handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();
		event.dataTransfer.dropEffect = this.props.effect || "copy";
		
	};
	
	handleDrop = event => {
		event.preventDefault();
		event.stopPropagation();
		
		this.setState({ isDropReady: false });
		
		let files = [ ...event.dataTransfer.files ];
		
		if (this.props.filter)
			files = files.filter(typeof this.props.filter == "function" ? this.props.filter : file => this.props.filter.test(file.type));
		
		if (files.length)
			this.props.onFiles(files);
		else
			this.props.onNoFiles?.();
		
	};
	
	handleDragLeave = event => {
		if (!this.node.contains(event.nativeEvent.fromElement)) {
			this.setState({ isDropReady: false });
			this.props.onDragLeave?.(event);
		}
		
	};
	
	handleRef = node => (this.node = node);
	
	render() {
		
		const { className, children } = this.props;
		const { isDropReady } = this.state;
		
		return (
			<div
				className={clsx(isDropReady && "Dropzone-dropReady", className)}
				onDragEnter={this.handleDragEnter}
				onDragLeave={this.handleDragLeave}
				onDragOver={this.handleDragOver}
				onDragStart={this.handleDragStart}
				onDrop={this.handleDrop}
				ref={this.handleRef}
			>
				{children}
			</div>
		);
	}
	
}
