import { Component } from "react";
import clsx from "clsx";
import moment from "moment";
import LinuxIcon from "@nesvet/missing-mui4-components/icons/Linux";
import WindowsIcon from "@nesvet/missing-mui4-components/icons/Windows";
import { ContextMenu, ContextMenuItem } from "insite-client-react";
import Box from "@material-ui/core/esm/Box";
import Chip from "@material-ui/core/esm/Chip";
import IconButton from "@material-ui/core/esm/IconButton";
import Link from "@material-ui/core/esm/Link";
import Paper from "@material-ui/core/esm/Paper";
import TextField from "@material-ui/core/esm/TextField";
import Typography from "@material-ui/core/esm/Typography";
import AppleIcon from "@material-ui/icons/esm/Apple";
import MoreVertIcon from "@material-ui/icons/esm/MoreVert";
import { inSite } from "$app";
import { createStyles } from "$styles";
import { Sheaf } from "./Sheaf";


const classes = createStyles(({ spacing, typography, shape, transitions }) => ({
	
	root: {
		display: "flex",
		flexFlow: "column nowrap",
		padding: spacing(3),
		borderRadius: shape.borderRadius * 2,
		
		"&:not(:last-child)": {
			marginBottom: spacing(3)
		},
		
		"&:not($actual)": {
			paddingTop: 0,
			paddingBottom: 0,
			backgroundColor: "transparent",
			opacity: .5,
			transitionProperty: "opacity",
			transitionDuration: transitions.duration.shortest,
			
			"&:hover": {
				opacity: .75
			}
		}
	},
	
	actual: {
		
		"& $platforms": {
			marginBottom: -spacing(1)
		}
	},
	
	version: {
		fontSize: typography.h5.fontSize
	},
	
	createdAt: {
		marginLeft: spacing(2)
	},
	
	id: {
		fontFamily: "monospace",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginBottom: spacing(1)
	},
	
	note: {
		minHeight: spacing(3),
		margin: [ -spacing(1), 0 ],
		padding: [ spacing(1), 0 ],
		whiteSpace: "pre-line"
	},
	
	platforms: {
		margin: [ spacing(1.5), 0, 0 ],
		
		"& > *": {
			marginRight: spacing(1),
			
			"& .MuiSvgIcon-root": {
				width: spacing(4),
				height: spacing(4)
			}
		}
	},
	
	sheafs: {
		marginBottom: spacing(2),
		padding: [ spacing(1), 0 ]
	}
	
}), "Camp");


const platformMap = {
	darwin: "macos",
	linux: "linux",
	win32: "windows"
};

function Platform({ platform, version }) {
	return (
		<Link download href={`/download/${platformMap[platform]}${version ? `/${version}` : ""}`}>
			{platform === "darwin" ? (
				<AppleIcon />
			) : platform === "linux" ? (
				<LinuxIcon />
			) : platform === "win32" ? (
				<WindowsIcon />
			) : null}
		</Link>
	);
}


export class Camp extends Component {
	
	state = {
		note: this.props.note,
		isNoteEdit: false
	};
	
	#handleNoteClick = () => this.setState({ isNoteEdit: true });
	
	#handleNoteChange = ({ target: { value: note } }) => this.setState({ note });
	
	#handleNoteBlur = async () => {
		if (this.state.note !== this.props.note)
			await inSite.ws.sendRequest("application.set-camp-note", this.props._id, this.state.note);
		this.setState({ isNoteEdit: false });
		
	};
	
	#menu;
	#menuProps = {
		anchorOrigin: { vertical: "bottom", horizontal: "center" },
		transformOrigin: { vertical: "top", horizontal: "center" },
		disablePortal: true,
		ref: menu => (this.#menu = menu)
	};
	
	#handleOpenMenuClick = event => this.#menu.open({ event });
	
	#handleDeleteClick = async () =>
		!this.props.sheafs.length &&
		confirm(`Удалить camp ${this.props.version}?`) &&
		await inSite.ws.sendRequest("application.delete-camp", this.props._id);
	
	
	render() {
		
		const { _id, version, isActual, platforms, createdAt, sheafs } = this.props;
		const { note, isNoteEdit } = this.state;
		
		return (
			<Paper className={clsx(classes.root, isActual && classes.actual)} elevation={isActual ? 6 : null}>
				<Box
					alignItems="center"
					display="flex"
					flexDirection="row"
					flexWrap="nowrap"
					ml={-1}
				>
					
					<Chip
						className={classes.version}
						label={version}
						color={isActual ? "primary" : "default"}
						variant={isActual ? "outlined" : "default"}
					/>
					
					<Typography className={classes.createdAt} color="textSecondary" variant="overline">
						{moment(createdAt).format("L LT")}
					</Typography>
					
					{!sheafs.length && (
						<>
							
							<Box flexGrow="1" />
							
							<IconButton onClick={this.#handleOpenMenuClick}>
								<MoreVertIcon />
							</IconButton>
							
							<ContextMenu {...this.#menuProps}>
								<ContextMenuItem onClick={this.#handleDeleteClick}>
									Удалить…
								</ContextMenuItem>
							</ContextMenu>
						
						</>
					)}
				
				</Box>
				
				<div className={classes.sheafs}>
					{sheafs.map(sheaf => (
						<Sheaf key={sheaf._id} {...sheaf} />
					))}
				</div>
				
				<Typography className={classes.id} color="textSecondary" variant="h5">
					{_id}
				</Typography>
				
				{isNoteEdit ? (
					<TextField
						value={note}
						autoFocus
						fullWidth
						multiline
						variant="outlined"
						onBlur={this.#handleNoteBlur}
						onChange={this.#handleNoteChange}
					/>
				) : (
					<Typography className={classes.note} variant="body2" onClick={this.#handleNoteClick}>
						{this.props.note}
					</Typography>
				)}
				
				{!!platforms.length && (
					<div className={classes.platforms}>
						{platforms.map(platform => (
							<Platform platform={platform} version={version} key={platform} />
						))}
					</div>
				)}
			
			</Paper>
		);
	}
}
