import { inSite } from "$app";
import { SelectablePaper } from "$components/SelectablePaper";
import { AddOrgDialog } from "./AddDialog";
import { Org } from "./Org";


export function Orgs() {
	return (
		<SelectablePaper.Container AddDialog={AddOrgDialog}>
			{inSite.orgs.sortedHierarchically.map(org => (
				<Org org={org} key={org._id} />
			))}
		</SelectablePaper.Container>
	);
}
