import { Component } from "react";
import { SubscriptionGroup } from "insite-subscriptions-react";
import LinearProgress from "@material-ui/core/esm/LinearProgress";
import { inSite } from "$app";
import { Dropzone } from "$components/Dropzone";
import { Loading } from "$components/Loading";
import { createStyles } from "$styles";
import { Camp } from "./Camp";


const classes = createStyles(({ spacing, palette }) => ({
	
	root: {
		position: "relative",
		flex: "1 0 auto",
		display: "flex",
		flexFlow: "column nowrap",
		paddingRight: spacing(7)
	},
	
	progress: {
		flex: `0 0 ${spacing(.5)}px`,
		margin: [ -spacing(2), 0, spacing(1.5) ]
	},
	
	dropArea: {
		flex: "1 0 auto",
		minWidth: "100%",
		minHeight: 300,
		
		"&.Dropzone-dropReady": {
			margin: [ -(spacing(2) + 3) ],
			padding: spacing(2),
			border: [ 3, "dashed", palette.primary.main ]
		}
	}
	
}), "Releases");


export class Releases extends Component {
	
	state = {
		loadingFile: false,
		transfer: null
	};
	
	subscriptionGroupDefinitions = [
		[
			"camps",
			"map",
			"application.releases.camps",
			updated => {
				if (updated)
					for (const camp of updated.added)
						camp.sheafs = [];
				
			}
		],
		[
			"sheafs",
			"map",
			"application.releases.sheafs",
			(updated, group) => {
				if (updated) {
					const { camps, sheafs } = group.values;
					
					const campsToSort = new Set();
					
					for (const sheaf of updated.added) {
						const camp = camps.get(sheaf.campToken);
						if (camp && !camp.sheafs.includes(sheaf)) {
							camp.sheafs.push(sheaf);
							campsToSort.add(camp);
						}
					}
					
					for (const camp of campsToSort)
						camp.sheafs.sort(sheafs.sortCompareFunction);
					
					if (updated.deleted.length)
						for (const camp of camps.sorted)
							for (const sheaf of camp.sheafs)
								if (!sheafs.has(sheaf._id))
									camp.sheafs.remove(sheaf);
				}
				
			}
		]
	];
	
	handleDropzoneFiles = ([ file ]) => {
		
		const transferKind =
			/\.sheaf$/.test(file.name) ?
				"application.sheaf" :
				/(development|production)\.zip$/.test(file.name) ?
					"application.bundle" :
					null;
		
		if (transferKind) {
			this.setState({ loadingFile: true });
			
			inSite.ws.transfer(transferKind, {
				
				data: file,
				
				collect: transferKind === "application.sheaf",
				
				onBegin: transfer => this.setState({ transfer }),
				
				onProgress: () => this.forceUpdate(),
				
				onEnd: () => this.setState({ loadingFile: false, transfer: null }),
				
				onError: (_, error) => {
					console.error(`${transferKind} transfer error:`, error);
					
					this.setState({ loadingFile: false, transfer: null });
					
				}
				
			});
		}
		
	};
	
	
	render() {
		
		const { loadingFile, transfer } = this.state;
		
		return (
			<div className={classes.root}>
				
				{loadingFile && (
					<LinearProgress className={classes.progress} value={transfer?.progress * 100 || 0} variant="determinate" />
				)}
				
				<SubscriptionGroup consistent definitions={this.subscriptionGroupDefinitions}>
					{(isInited, [ camps ]) => isInited ? (
						<Dropzone className={classes.dropArea} onFiles={this.handleDropzoneFiles}>
							{camps.sorted.map(camp => (
								<Camp key={camp._id} {...camp} />
							))}
						</Dropzone>
					) : (
						<Loading />
					)}
				</SubscriptionGroup>
			</div>
		);
	}
	
}
