import { Component } from "react";
import clsx from "clsx";
import moment from "moment";
import { ContextMenu, ContextMenuItem } from "insite-client-react";
import Box from "@material-ui/core/esm/Box";
import IconButton from "@material-ui/core/esm/IconButton";
import Switch from "@material-ui/core/esm/Switch";
import TextField from "@material-ui/core/esm/TextField";
import Typography from "@material-ui/core/esm/Typography";
import MoreVertIcon from "@material-ui/icons/esm/MoreVert";
import { inSite } from "$app";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, palette, transitions }) => ({
	
	root: {
		paddingLeft: spacing(2),
		paddingRight: spacing(2),
		borderLeft: [ 2, "solid", palette.divider ],
		
		"&:not(:last-child)": {
			marginBottom: spacing(1)
		},
		
		"&:not($actual)": {
			opacity: .5,
			transitionProperty: "opacity",
			transitionDuration: transitions.duration.shortest,
			
			"&:hover": {
				opacity: .75
			}
		}
	},
	actual: {},
	
	isActual: {
		marginLeft: -spacing(1),
		marginRight: spacing(.5)
	},
	
	version: {
		lineHeight: 1
	},
	
	createdAt: {
		marginLeft: spacing(2)
	},
	
	note: {
		minHeight: spacing(3),
		margin: [ -spacing(2), 0, -spacing(1) ],
		padding: [ spacing(2), 0, spacing(1) ],
		whiteSpace: "pre-line"
	}
	
}), "Sheaf");


export class Sheaf extends Component {
	
	state = {
		note: this.props.note,
		isNoteEdit: false
	};
	
	#handleActualChange = async () =>
		!this.props.isActual &&
		confirm(`Установить версию ${this.props.version} в качестве актуальной?`) &&
		await inSite.ws.sendRequest("application.make-sheaf-actual", this.props._id);
	
	#handleNoteClick = () => this.setState({ isNoteEdit: true });
	
	#handleNoteChange = ({ target: { value: note } }) => this.setState({ note });
	
	#handleNoteBlur = async () => {
		if (this.state.note !== this.props.note)
			await inSite.ws.sendRequest("application.set-sheaf-note", this.props._id, this.state.note);
		
		this.setState({ isNoteEdit: false });
		
	};
	
	#menu;
	#menuProps = {
		anchorOrigin: { vertical: "bottom", horizontal: "center" },
		transformOrigin: { vertical: "top", horizontal: "center" },
		disablePortal: true,
		ref: menu => (this.#menu = menu)
	};
	
	#handleOpenMenuClick = event => this.#menu.open({ event });
	
	#handleDeleteClick = async () =>
		!this.props.isActual &&
		confirm(`Удалить sheaf ${this.props.version}?`) &&
		await inSite.ws.sendRequest("application.delete-sheaf", this.props._id);
	
	
	render() {
		
		const { version, isActual, createdAt } = this.props;
		const { note, isNoteEdit } = this.state;
		
		return (
			<div className={clsx(classes.root, isActual && classes.actual)}>
				<Box
					alignItems="center"
					display="flex"
					flexDirection="row"
					flexWrap="nowrap"
					mb={1}
				>
					<Switch
						className={classes.isActual}
						checked={isActual}
						color="primary"
						size="small"
						onChange={this.#handleActualChange}
					/>
					
					<Typography className={classes.version} variant="h5">
						{version}
					</Typography>
					
					<Typography className={classes.createdAt} color="textSecondary" variant="overline">
						{moment(createdAt).format("L LT")}
					</Typography>
					
					{!isActual && (
						<>
							
							<Box flexGrow="1" />
							
							<IconButton size="small" onClick={this.#handleOpenMenuClick}>
								<MoreVertIcon />
							</IconButton>
							
							<ContextMenu {...this.#menuProps}>
								<ContextMenuItem onClick={this.#handleDeleteClick}>
									Удалить…
								</ContextMenuItem>
							</ContextMenu>
						
						</>
					)}
				
				</Box>
				
				{isNoteEdit ? (
					<TextField
						value={note}
						autoFocus
						fullWidth
						multiline
						variant="outlined"
						onBlur={this.#handleNoteBlur}
						onChange={this.#handleNoteChange}
					/>
				) : (
					<Typography className={classes.note} variant="body2" onClick={this.#handleNoteClick}>
						{this.props.note}
					</Typography>
				)}
			
			</div>
		);
	}
}
